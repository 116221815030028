.btn-express {
  font-family: Inter, sans-serif;
  font-size: 16px !important;
  font-weight: 600 !important;
  width: 100%;
  height: 40px;
  border: 0;
  border-radius: 4px;
  margin: 16px 0 8px;
  display: block;
  text-align: center;
  line-height: 24px;
  align-items: flex-start;
}

.btn-express-primary {
  color: var(--components-button-primary-text-default);
  background: var(--components-button-primary-background-default);
}

.btn-express-tertiary {
  color: var(--components-button-tertiary-text-default) !important;
  background: var(--components-button-tertiary-background-default);
  border: 1px solid var(--border-primary) !important;
}

.btn-express-danger {
  color: var(--components-button-secondary-text-default) !important;
  background: var(--background-state-danger-high-emphasize);
}

.express-common {
  line-height: 24px;
  font-family: Inter, sans-serif !important;
  text-transform: none !important;
}

.heading-h1-express {
  font-size: 32px;
  font-weight: 600;
  margin-bottom: 20px;
  line-height: 44px;
}

.heading-h3-express {
  font-size: 16px;
  font-weight: 600;
}

.center {
  text-align: center;
}

.start {
  text-align: start;
}

.express-form-field-label {
  margin: 10px 0 -2px 0;
  font-weight: normal;
  font-size: 14px;
}

.top-bar-express {
  background-color: var(--background-primary);
}

.express-form-field input {
  padding: 8px 12px;
  display: block;
  width: 100%;
  height: 40px;
  font-size: 16px;
  color: var(--components-button-primary-text-default);
  border: 1px solid var(--border-primary);
  border-radius: 4px;
  outline: none;
  font-weight: normal;
}

.express-form-field::after {
  content: "\00A0";
  display: none;
  color: #ff3351;
  font-size: 12px;
  padding-top: 5px;
}

.express-icon-green {
  color: var(--icon-state-success);
}

.express-selected-lang {
  font-size: 14px;
  font-weight: 600;
}

.express-extra-text {
  color: var(--text-tertiary);
  margin: 8px 0;
  font-weight: normal;
  margin-bottom: -2px;
  font-size: 14px;
}


.express-text-primary {
  color: var(--text-primary);
}

.express-account-help {
  display: flex;
  align-items: baseline;
  justify-content: center;
}

.express-text-secondary {
  color: var(--text-secondary);
  font-weight: normal;
  font-size: 16px;
}

.express-link-action {
  color: var(--text-primary);
  font-weight: 600;
  font-size: 14px;
}

.express-terms-statement {
  font-weight: normal;
  font-size: 14px;
  margin: 40px 0;
  & a {
    font-size: 16px;
      font-weight: 600;
    color: var(--text-state-success);
  }
}

.express-success-screen-line {
  text-align: center;
  margin-top: 20px;
}

.express-form-distant-item {
  text-align: center;
  margin-top: 40px !important;
}

.express-modal-icon-block {
  text-align: right;
  padding: 16px 8px 0 0;
}

.express-icon-button {
  color: var(--components-button-primary-text-default) !important;
  border: 1px solid var(--border-primary) !important;
}