h2 {
  text-align: center;
  margin-bottom: 30px;
}

.client-management ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

.client-management li {
  display: flex;
  align-content: space-between;
  align-items: center;
  margin: 0;
  padding: 10px;
  color: #1f9947;
  border-bottom: 1px solid #eee;
}

.client-management li button {
  color: black;
  margin-left: auto;
}

.client-management li img {
  width: 50px;
  height: 50px;
  margin-right: 10px;
  object-fit: contain;
}

.client-management li:hover {
  background-color: #eee;
}
