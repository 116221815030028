.container {
  position: relative;
  width: 100%;
  padding: 0px;
}

.icon-btn {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 0;
  margin: 0px 6px;
}

.icon-btn > button {
  font-size: 16px;
}

.icon-btn > button:hover {
  background-color: transparent;
}

.intl-tel-input {
  display: flex;
  column-gap: 0.6rem;
}

.intl-tel-input input {
  font-family: "Inter", sans-serif !important;
  flex: 1;
  background-color: #eaedf1;
  color: #2d2e2e !important;
  border-radius: 4px;
  padding: 0px 10px !important;
  border: 1px solid transparent;
}

.intl-tel-input input:focus {
  outline: none;
  border: 1px solid #889db4 !important;
  box-shadow: none;
}

.intl-tel-input .flag-container {
  font-family: "Inter", sans-serif !important;
  flex: 0;
  background-color: #eaedf1;
  color: #2d2e2e !important;
  border-radius: 4px;
  position: relative;
  padding: 0px 0.5em !important;
}

.intl-tel-input .flag-container .selected-flag {
  background-color: #eaedf1 !important;
  font-family: "Inter", sans-serif;
  display: flex !important;
}

.intl-tel-input .flag-container .selected-dial-code {
  padding: 0px !important;
  margin-left: 8px;
}

.intl-tel-input .selected-flag .iti-flag {
  position: absolute;
  left: 0;
}

.intl-tel-input .selected-flag .arrow {
  position: absolute;
  right: 0;
  margin: 0px !important;
}

.intl-tel-input .selected-flag .arrow.down::after {
  background-image: url("../../images/arrow-down.svg");
  background-size: 14px 14px;
  margin-top: 2px;
  display: inline-block;
  width: 14px;
  height: 14px;
  content: "";
}

.intl-tel-input .selected-flag .arrow.up::after {
  background-image: url("../../images/arrow-up.svg");
  background-size: 14px 14px;
  margin-top: 2px;
  display: inline-block;
  width: 14px;
  height: 14px;
  content: "";
}

.intl-tel-input .flag-container .country-list {
  left: 1px;
  border-radius: 4px;
}

.tel-error {
  background-color: #fdd9ce !important;
}

.error-msg > p {
  margin: 0px;
  color: #f7430a;
  font-size: 12px;
}

.express-intl-tel-input {
  display: flex;
  column-gap: 0;
  grid-column-gap: 0;
}

.express-intl-tel-input input {
  font-family: "Inter", sans-serif !important;
  flex: 1;
  background-color: var(--background-primary);
  color: var(--text-primary) !important;
  border-radius: 0 4px 4px 0 !important;
  padding: 0px 10px !important;
  border: 1px solid var(--border-primary);
}

.express-intl-tel-input input:focus {
  outline: none;
  border: 1px solid var(--components-input-input-field-border-default-focus) !important;
  box-shadow: none;
}

.express-intl-tel-input .flag-container {
  font-family: "Inter", sans-serif !important;
  flex: 0;
  background-color: var(--background-secondary);
  color: var(--text-primary) !important;
  border-radius: 4px 0 0 4px;
  position: relative;
  padding: 0px 0.5em !important;
  border-width: 1px 0 1px 1px;
  border-color: var(--border-primary);
  border-style: solid;
}

.express-intl-tel-input .flag-container .selected-flag {
  z-index: 1;
  position: relative;
  height: 100%;
  justify-content: center;
  align-items: center;
  background-color: var(--background-secondary) !important;
  display: flex !important;
  width: 93px;
}

.express-intl-tel-input .flag-container .selected-dial-code {
  padding: 0px !important;
  margin-left: 8px;
}

.express-intl-tel-input .selected-flag .iti-flag {
  position: absolute;
  left: 0;
}

.express-intl-tel-input .selected-flag .arrow {
  position: absolute;
  right: 0;
  margin: 0px !important;
}

.express-intl-tel-input .selected-flag .arrow.down::after {
  background-image: url("../../images/arrow-down.svg");
  background-size: 14px 14px;
  margin-top: 2px;
  display: inline-block;
  width: 14px;
  height: 14px;
  content: "";
}

.express-intl-tel-input .selected-flag .arrow.up::after {
  background-image: url("../../images/arrow-up.svg");
  background-size: 14px 14px;
  margin-top: 2px;
  display: inline-block;
  width: 14px;
  height: 14px;
  content: "";
}

.express-intl-tel-input .flag-container .country-list {
  left: 1px;
  border-radius: 4px;
}
