.btn-careem-aurora {
  border-radius: 40px;
  color: #fff;
  background: var(--background-state-success-high-emphasize);
  width: 100%;
  height: 48px;
  border: 0;
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  margin: 16px 0 8px;
  display: block;
  text-align: center;
  line-height: 48px;
  align-items: flex-start;
}

.btn-careem-aurora:disabled {
  background: var(--components-button-counter-primary-background-pressed);
}

#btn-send-again:disabled {
  background: white;
  color: #9dafc0;
  border: 1px solid #9dafc0;
}

.text-description {
  text-align: center;
  color: #355273;
  font-family: Inter, sans-serif;
  font-weight: 400;
  font-size: 16px;
}

.btn-careem-aurora:hover {
  background-color: var(--components-button-counter-primary-background-pressed);
}

.heading-h1-aurora {
  font-size: 24px;
  font-weight: 600;
  line-height: 32px;
  font-family: "Inter", sans-serif;
}
