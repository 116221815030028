/* Welcome to Compass.
 * In this file you should write your main styles. (or centralize your imports)
 * Import this file using the following HTML or equivalent:
 * <link href="/stylesheets/screen.css" media="screen, projection" rel="stylesheet" type="text/css" /> */

@font-face {
  font-family: "CircularStd-Medium";
  src: url("../fonts/CircularStd-Medium.otf") format("opentype");
}

@font-face {
  font-family: "CircularStd-Bold";
  src: url("../fonts/CircularStd-Bold.otf") format("opentype");
}

@font-face {
  font-family: "CircularStd-Book";
  src: url("../fonts/CircularStd-Book.otf") format("opentype");
}

@font-face {
  font-family: "CircularStd-Black";
  src: url("../fonts/CircularStd-Black.otf") format("opentype");
}

@font-face {
  font-family: "NotoKufiArabic-Regular";
  src: url("../fonts/NotoKufiArabic-Regular.eot?#iefix")
      format("embedded-opentype"),
    url("../fonts/NotoKufiArabic-Regular.woff") format("woff"),
    url("../fonts/NotoKufiArabic-Regular.ttf") format("truetype"),
    url("../fonts/NotoKufiArabic-Regular.svg#NotoKufiArabic-Regular")
      format("svg");
}

@font-face {
  font-family: "NotoKufiArabic-Bold";
  src: url("../fonts/NotoKufiArabic-Bold.eot?#iefix")
      format("embedded-opentype"),
    url("../fonts/NotoKufiArabic-Bold.woff") format("woff"),
    url("../fonts/NotoKufiArabic-Bold.ttf") format("truetype"),
    url("../fonts/NotoKufiArabic-Bold.svg#NotoKufiArabic-Bold") format("svg");
}

@font-face {
  font-family: "Inter";
  src: url("../fonts/Inter-VariableFont_slnt,wght.ttf") format("truetype");
}

* {
  -webkit-font-smoothing: antialiased;
  outline: none;
}

a:hover {
  text-decoration: none;
  color: var(--text-state-success);
}

body {
  font-size: 16px;
}

@media screen and (max-width: 40em) {
  body {
    font-size: 15px;
    background: none;
  }
}

/*
My take on pure CSS material design inputs and drop-down
Influenced heavily by: https://codepen.io/sevilayha/pen/IdGKH
*/

/*
A dropdown menu that covers the content
Note: MUST be placed inside a relative position element (works with .material-form-field)
Expected html:
<ul class="material-dropdown">
<li>Item 1</li>
<li>Item 2</li>
<li>Item 3</li>
...
</ul>
*/

.material-dropdown {
  position: absolute;
  left: 0;
  width: 100%;
  height: 0;
  margin: 0;
  padding: 0;
  list-style: none;
  overflow: hidden;
  background: var(--background-primary);
  color: var(--text-primary);
  z-index: 1000;
  transition: 0.2s ease-in height, step-end box-shadow 0.2s;
  -moz-transition: 0.2s ease-in height, step-end box-shadow 0.2s;
  -webkit-transition: 0.2s ease-in height, step-end box-shadow 0.2s;
}

.material-dropdown.material-dropdown-open {
  box-shadow: 0px 0px 2px 2px rgba(0, 0, 0, 0.2);
}

.material-dropdown li {
  padding: 10px;
  cursor: default;
  user-select: none;
}

.material-dropdown li:hover {
  background-color: var(--background-state-success-mid-emphasize);
}

.material-dropdown li.material-dropdown-selected {
  background-color: #9fa8da;
}

/*
A material design form field
Expects this html (note the required attribute on the input):
<div class="material-form-field">
<input type="text" required/>
<label class="material-form-field-label">Form field title</label>
</div>
*/

.dl-horizontal.user-profile dt {
  text-overflow: clip;
}

.material-form-field {
  font-family: "CircularStd-Book", "NotoKufiArabic-Regular", sans-serif;
  position: relative;
  display: block;
  color: var(--components-input-input-field-text-default);
  padding: 7px 0 7px 0;
  -webkit-font-smoothing: antialiased;
}

.material-form-field::after {
  content: "\00A0";
  display: none;
  color: var(--text-state-danger);
  font-size: 12px;
  padding-top: 5px;
}

.material-form-field .material-form-field-label {
  position: absolute;
  display: block;
  top: 23px;
  left: 15px;
  transition: 0.2s ease all;
  -moz-transition: 0.2s ease all;
  -webkit-transition: 0.2s ease all;
  pointer-events: none;
  cursor: text;
  font-weight: normal;
}
@media screen and (max-width: 40em) {
  .material-form-field .material-form-field-label {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    padding-right: 20px;
  }
}

[dir="rtl"] .material-form-field .material-form-field-label {
  right: 15px;
}

.material-form-field input {
  font-family: "CircularStd-Book", "NotoKufiArabic-Regular", sans-serif;
  padding: 18px 14px 0px 14px;
  display: block;
  width: 100%;
  height: 50px;
  font-size: 16px;
  color: var(--components-input-input-field-text-default) !important;
  border: 1px solid var(--components-input-input-field-border-default);
  border-radius: 4px;
  outline: none;
  font-weight: normal;
}

.material-form-field input ~ .material-form-field-label {
  top: 13px;
  font-size: 14px;
}

@media screen and (max-width: 40em) {
  .material-form-field input ~ .material-form-field-label {
    top: 14px;
    font-size: 12px;
  }
}

.material-form-field input:focus {
  outline: none;
  color: var(--components-input-input-field-text-default-focus);
  padding-bottom: -2;
  border: 2px solid var(--components-input-input-field-border-default-focus);
}

.material-form-field input:focus ~ .material-form-field-label {
  color: var(--components-input-label-text-default);
}

.material-form-field input:focus ~ .material-dropdown {
  height: 228px;
  box-shadow: 0px 0px 2px 2px rgba(0, 0, 0, 0.2);
  transition: 0.2s ease-out height, step-start box-shadow 0.2s;
  -moz-transition: 0.2s ease-out height, step-start box-shadow 0.2s;
  -webkit-transition: 0.2s ease-out height, step-start box-shadow 0.2s;
}

.material-form-field.material-form-field-invalid {
  color: var(--text-state-danger);
}

.material-form-field.material-form-field-invalid::after {
  content: attr(data-validationError);
}

.material-form-field.material-form-field-invalid
  input:focus
  ~ .material-form-field-label {
  color: var(--text-state-danger);
}

.material-form-field .intl-tel-input {
  width: 100%;
  direction: ltr;
}

.material-form-field .intl-tel-input input {
  padding: 0;
}

.material-form-field .intl-tel-input .flag-container {
  color: #2e2d2e;
}

@media only screen and (max-width: 480px) {
  .gc-reset {
    transform: scale(0.85) !important;
    -webkit-transform: scale(0.85) !important;
    transform-origin: 0 0 !important;
    -webkit-transform-origin: 0 0 !important;
  }
}

.form {
  font-family: "CircularStd-Book", "NotoKufiArabic-Regular", sans-serif;
  padding: 2em 3em;
  background: var(--background-primary);
  box-shadow: 0 5px 14px 0 #cfd8df;
  border-radius: 4px;
  margin-top: 120px;
  margin-bottom: 18px;
}

@media screen and (max-width: 40em) {
  .form {
    margin-top: 40px;
    padding: 18px 5%;
    box-shadow: none;
  }
}

h1,
h3 {
  width: 100%;
  text-align: center;
  font-family: "CircularStd-Book", "NotoKufiArabic-Regular", sans-serif;
  font-weight: normal;
}

h5 {
  width: 100%;
  text-align: center;
  color: var(--text-primary);
}

h1 {
  font-size: 24px;
  color: var(--text-primary);
  font-weight: normal;
  margin-bottom: 0;
  letter-spacing: -1px;
}

@media screen and (max-width: 40em) {
  h1 {
    font-size: 20px;
    letter-spacing: 0;
  }
}

h3 {
  font-size: 18px;
  color: var(--text-primary);
  margin-bottom: 15px;
  margin-top: 15px;
  line-height: 1.2;
}

@media screen and (max-width: 40em) {
  h3 {
    font-size: 15px;
    margin-bottom: 20px;
  }
}

header {
  width: 100%;
  text-align: center;
}

header img {
  width: 60px;
  margin: 40px 20px 40px;
}

@media screen and (max-width: 40em) {
  header img {
    width: 42px;
    margin: 25px 20px 25px;
  }
}

.invalid {
  color: var(--text-state-danger);
  margin: 8px 0;
  font-weight: normal;
  margin-bottom: -2px;
  display: none;
  font-size: 14px;
}

@media screen and (max-width: 40em) {
  .invalid {
    font-size: 12px;
  }
}

label.error {
  color: var(--text-state-danger);
  margin: 8px 0;
  font-weight: normal;
  margin-bottom: -2px;
  font-size: 14px;
}

@media screen and (max-width: 40em) {
  label.error {
    font-size: 12px;
  }
}

.show .invalid {
  display: block;
}

.show input {
  border-color: var(--text-state-danger) !important;
}

.show {
  display: block;
}

.invalid-signup {
  color: var(--text-state-danger);
  margin: 8px 0;
  font-weight: normal;
  margin-bottom: -2px;
  font-size: 14px;
}

.careem-wink-header-2em {
  background: url("../images/careem-favicon.png");
  background-size: 2em 2em;
  background-repeat: no-repeat;
  background-position: center;
  width: 4em;
  height: 4em;
  display: block;
  cursor: pointer;
  position: absolute;
  top: 0;
  left: 0;
}

.errorpage-logo {
  filter: contrast(0.3);
}

.errorpage-logo:hover {
  filter: contrast(0.8);
}

.errorpage-logo:active {
  filter: contrast(1);
}

.break-words {
  overflow-wrap: break-word;
}
.button-looks-like-link {
  background-color: transparent;
  border: none;
  color: var(--components-link-primary-text-default);
  text-decoration: none;
  cursor: pointer;
  padding: 0;
  font: inherit;
}

.button-looks-like-link:hover {
  color: var(--components-link-primary-text-hover);
}
.help-lang-container {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.help-center-icon {
  margin-top: -4px;
  margin-right: 1px;
  margin-left: 1px;
  color: var(--icon-secondary);
}

.help-center-button > span {
  font-size: 16px;
  line-height: 24px;
  font-family: "Inter", sans-serif;
  font-weight: 600;
}

.help-center-button {
  color: var(--text-secondary);
  background: var(--background-primary);
  width: 160px;
  height: 40px;
  border-radius: 4px;
  margin: 0px 16px;
  display: block;
  text-align: center;
  border: 1px solid var(--border-primary);
}

.btn-careem-primary {
  color: var(--components-button-primary-text-default);
  background: var(--components-button-primary-background-default);
  border: 1px solid var(--components-button-primary-border-default);
  width: 100%;
  min-width: 350px;
  height: 40px;
  border-radius: 4px;
  font-family: "Inter", sans-serif;
  font-size: 16px;
  font-weight: 600;
  margin: 24px 0 0px;
  display: block;
  text-align: center;
  line-height: 24px;
  cursor: pointer;
}

.btn-careem-primary:hover {
  color: var(--components-button-primary-text-hover);
  background: var(--components-button-primary-background-hover);
  border: 1px solid var(--components-button-primary-border-hover);
}

.btn-careem-primary[disabled] {
  cursor: not-allowed;
  background: var(--components-button-primary-background-disabled);
  color: var(--components-button-primary-text-disabled);
}

.btn-careem-tertiary {
  color: var(--components-button-tertiary-text-default);
  background: var(--components-button-tertiary-background-default);
  border: 1px solid var(--components-button-tertiary-border-default);
  width: 100%;
  height: 40px;
  border-radius: 4px;
  margin: 24px 0 8px;
  display: block;
  font-family: Inter, Sans-serif;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  align-items: flex-start;
  cursor: pointer;
}

.btn-careem-tertiary:hover {
  color: var(--components-button-tertiary-text-hover);
  background: var(--components-button-tertiary-background-hover);
  border: 1px solid var(--components-button-tertiary-border-hover);
}

.btn-careem-tertiary[disabled] {
  cursor: not-allowed;
  background: var(--components-button-primary-background-disabled);
  color: var(--components-button-primary-text-disabled);
  border: 1px solid var(--components-button-tertiary-border-disabled);
}
.blocked-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 30px;
}

.blocked-container h1 {
  font-size: 20px;
  line-height: 24px;
  font-weight: 600;
  color: var(--text-primary);
}
.blocked-container span {
  font-size: 12px;
  line-height: 18px;
  font-weight: 400;
  color: var(--text-primary);
}
.btn-careem {
  color: var(--components-button-primary-text-default);
  background: var(--components-button-primary-background-default);
  width: 100%;
  height: 40px;
  border: 0;
  border-radius: 4px;
  margin: 24px 0 8px;
  display: block;
  font-family: Inter, Sans-serif;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  align-items: flex-start;
}

a.btn-careem:hover {
  color: var(--components-button-primary-text-hover);
}

.btn-careem[disabled] {
  cursor: not-allowed;
  background: var(--components-button-primary-background-disabled);
  color: var(--components-button-primary-text-disabled);
}

.terms-text {
  font-size: 12px;
  font-weight: 200;
  line-height: 150%;
  color: var(--text-tertiary);
  margin-top: 2em;
  opacity: 0.5;
  text-align: center;
}

.grecaptcha-badge {
  display: none !important;
}

@media screen and (max-width: 40em) {
  .btn-careem {
    font-size: 16px;
  }
  .btn-careem-primary {
    font-size: 16px;
    min-width: fit-content;
    padding: 0 16px;
  }
}

.btn-careem:hover:enabled {
  background: var(--components-button-primary-background-hover);
}

.no-btn-careem {
  color: var(--components-button-tertiary-text-default);
  background: var(--components-button-tertiary-background-default);
  width: 100%;
  height: 48px;
  border: 0;
  border-radius: 4px;
  font-size: 16px;
  margin: 16px 0 8px;
  display: block;
  text-align: center;
  line-height: 48px;
  align-items: flex-start;
}

a.no-btn-careem:hover {
  color: var(--components-button-tertiary-text-hover);
}

.info {
  margin-top: 1em;
}

.center {
  text-align: center;
}

.fb-hint {
  color: #6d6e70;
  margin-bottom: 10px;
}

#or {
  position: relative;
  width: 100%;
  height: 50px;
  line-height: 50px;
  text-align: center;
  font-size: 20px;
  color: var(--text-primary-inverse);
  margin: 2px 0 2px;
}

@media screen and (max-width: 40em) {
  #or {
    font-size: 16px;
  }
}

#or::before,
#or::after {
  position: absolute;
  width: 42%;
  height: 2px;
  top: 24px;
  background-color: #e1e4e6;
  content: "";
}

#or::before {
  left: 0;
}

#or::after {
  right: 0;
}

.footer-link {
  width: 100%;
  text-align: center;
  font-family: "CircularStd-Book", "NotoKufiArabic-Regular", sans-serif;
  margin-top: 1em;
  z-index: 1;
}

@media screen and (max-width: 40em) {
  .footer-link {
    margin-bottom: 30px;
  }
}

@media screen and (max-width: 40em) {
  .br {
    display: none;
  }
}

.bigger {
  font-size: 18px;
}

@media screen and (max-width: 40em) {
  .bigger {
    font-size: 14px;
  }
}

/*********** Material design loader ******************/

.full-page-animate {
  position: fixed;
  overflow-y: hidden;
  width: 100%;
  height: 100%;
  top: 0px;
  opacity: 1;
  z-index: 99999999;
  transition: 1s ease all !important;
}

.full-page-animate .animation-careem {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 10;
}

.full-page-animate .animation-careem .span,
.full-page-animate .animation-careem .span_large {
  float: left;
  width: 100px;
  height: 100px;
  vertical-align: middle;
  border-radius: 1px;
  margin-right: 100px;
  zoom: 1.5;
}

.full-page-animate .animation-careem .span:last-child {
  margin-right: 0px;
}

.full-page-animate .animation-careem .typing_loader {
  width: 6px;
  height: 6px;
  border-radius: 50%;
  -webkit-animation: typing 1s linear infinite alternate;
  -moz-animation: Typing 1s linear infinite alternate;
  animation: typing 1s linear infinite alternate;
  margin: 46px auto;
  position: relative;
  left: -12px;
}

@-webkit-keyframes typing {
  0% {
    background-color: white;
    box-shadow: 12px 0px 0px 0px rgba(255, 255, 255, 0.2),
      24px 0px 0px 0px rgba(255, 255, 255, 0.2);
  }

  25% {
    background-color: rgba(255, 255, 255, 0.4);
    box-shadow: 12px 0px 0px 0px white,
      24px 0px 0px 0px rgba(255, 255, 255, 0.2);
  }

  75% {
    background-color: rgba(255, 255, 255, 0.4);
    box-shadow: 12px 0px 0px 0px rgba(255, 255, 255, 0.2),
      24px 0px 0px 0px white;
  }
}

@-moz-keyframes typing {
  0% {
    background-color: white;
    box-shadow: 12px 0px 0px 0px rgba(255, 255, 255, 0.2),
      24px 0px 0px 0px rgba(255, 255, 255, 0.2);
  }

  25% {
    background-color: rgba(255, 255, 255, 0.4);
    box-shadow: 12px 0px 0px 0px white,
      24px 0px 0px 0px rgba(255, 255, 255, 0.2);
  }

  75% {
    background-color: rgba(255, 255, 255, 0.4);
    box-shadow: 12px 0px 0px 0px rgba(255, 255, 255, 0.2),
      24px 0px 0px 0px white;
  }
}

@keyframes typing {
  0% {
    background-color: white;
    box-shadow: 12px 0px 0px 0px rgba(255, 255, 255, 0.2),
      24px 0px 0px 0px rgba(255, 255, 255, 0.2);
  }

  25% {
    background-color: rgba(255, 255, 255, 0.4);
    box-shadow: 12px 0px 0px 0px white,
      24px 0px 0px 0px rgba(255, 255, 255, 0.2);
  }

  75% {
    background-color: rgba(255, 255, 255, 0.4);
    box-shadow: 12px 0px 0px 0px rgba(255, 255, 255, 0.2),
      24px 0px 0px 0px white;
  }
}

.full-page-animate .backdrop2 {
  position: fixed;
  overflow-y: hidden;
  background-color: #000000;
  width: 100%;
  height: 100%;
  top: 0px;
  opacity: 0.6;
}

.stop-loading {
  z-index: -1;
  transition: 1s ease all !important;
}

.stop-loading .backdrop2 {
  opacity: 0;
}

.stop-loading .animation-careem {
  opacity: 0;
}

.simple-modal .modal[aria-hidden="true"] {
  display: none;
}

.simple-modal .modal {
  font-family: -apple-system, BlinkMacSystemFont, avenir next, avenir,
    helvetica neue, helvetica, ubuntu, roboto, noto, segoe ui, arial, sans-serif;
}

.simple-modal .modal__overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.6);
  display: flex;
  justify-content: center;
  align-items: center;
}

.simple-modal .modal__container {
  background-color: var(--background-primary);
  padding: 30px;
  max-width: 500px;
  max-height: 100vh;
  border-radius: 4px;
  overflow-y: auto;
  box-sizing: border-box;
}

@media screen and (max-width: 40em) {
  .simple-modal .modal__container {
    margin: 0 15px;
    padding: 30px;
  }

}

.simple-modal .modal__header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.simple-modal .modal__title {
  margin-top: 0;
  margin-bottom: 0;
  font-size: 2rem;
  line-height: 1.25;
  color: var(--text-brand-careem);
  box-sizing: border-box;
  text-align: left;
}

.simple-modal .modal__close {
  background: transparent;
  border: 0;
}

.simple-modal .modal__header .modal__close:before {
  content: "\2715";
}

.simple-modal .modal__content {
  margin-top: 2rem;
  margin-bottom: 2rem;
  line-height: 1.5;
  color: rgba(0, 0, 0, 0.8);
}

.simple-modal .modal__btn {
  font-size: 1.5rem;
  padding-left: 1rem;
  padding-right: 1rem;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  background-color: var(--components-button-tertiary-background-default);
  color: var(--components-button-tertiary-text-default);
  border-radius: 0.25rem;
  border-style: none;
  border-width: 0;
  cursor: pointer;
  -webkit-appearance: button;
  appearance: button;
  text-transform: none;
  overflow: visible;
  line-height: 1.15;
  margin: 0;
  will-change: transform;
  -moz-osx-font-smoothing: grayscale;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  -webkit-transform: translateZ(0);
  transform: translateZ(0);
  transition: -webkit-transform 0.25s ease-out;
  transition: transform 0.25s ease-out;
  transition: transform 0.25s ease-out, -webkit-transform 0.25s ease-out;
}

.simple-modal .modal__btn:focus,
.simple-modal .modal__btn:hover {
  -webkit-transform: scale(1.05);
  transform: scale(1.05);
}

.simple-modal .modal__btn-primary {
  background-color: var(--background-primary);
  color: var(--text-primary);
  padding: 10px 20px;
}

.simple-modal .modal__footer {
  text-align: right;
}

[dir="rtl"] .intl-tel-input .country-list .country .dial-code {
  direction: ltr;
}

.mt0 {
  margin-top: 0;
}

/*# sourceMappingURL=screen.css.map */

#lang-menu {
  display: inline-block;
  position: relative;
  margin-bottom: 5px;
}

#lang-menu.right-to-left-lang {
  float: left;
}

#lang-menu.left-to-right-lang {
  float: right;
}

#lang-menu:hover {
  cursor: pointer;
}

.clearFloats {
  content: " ";
  clear: both;
}

#lang-menu .fa-angle-down,
#lang-menu .selected-lang {
  display: inline-block;
  vertical-align: middle;
}

#lang-menu label span {
  font-weight: normal;
}

#lang-menu.right-to-left-lang .selected-lang {
  padding: 0 5px 0 5px;
}

#lang-menu.left-to-right-lang .selected-lang {
  padding: 0 5px 0 5px;
  color: var(--text-secondary);
}

#lang-menu .lang-list-wrapper {
  overflow-y: hidden;
  position: absolute;
  z-index: 15;
}

.closed-lang-list-wrapper {
  height: 0;
  -webkit-transition: none;
  -moz-transition: none;
  -ms-transition: none;
  -o-transition: none;
  transition: none;
}

.opened-lang-list-wrapper,
#show-lang-list:checked + #lang-list {
  height: 100px;
  -webkit-transition: height 0.25s ease-in;
  -moz-transition: height 0.25s ease-in;
  -ms-transition: height 0.25s ease-in;
  -o-transition: height 0.25s ease-in;
  transition: height 0.25s ease-in;
}

#lang-menu ul {
  list-style: none;
  margin: 0;
  padding: 5px 0;
  background: var(--background-tertiary);
  border: 1px solid var(--border-primary);
  box-shadow: none;
  text-transform: uppercase;
  border-radius: 5px;
}

#lang-menu li.hidden {
  display: none;
}

#lang-menu li {
  display: block;
  font-size: 12px;
  padding: 4px 20px;
  text-decoration: none;
  margin: 0;
  border-radius: 5px;
}

[type="checkbox"] + span {
  color: var(--icon-state-info);
  font-size: 14px;
  font-weight: normal;
}
/* todo: need to check this with hamad */
#lang-menu li:hover {
  background-color: #f5f5f5;
  color: #28bb4e;
}

.signup-header {
  margin-bottom: 25px;
}

/*# sourceMappingURL=screen.css.map */

/* IDP specific styles */

.client-logo {
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.consent-notice {
  text-align: center;
  padding-top: 20px;
}

.btn-approve {
  color: var(--components-button-primary-text-default);
  background: var(--components-button-primary-background-default);
  width: 100%;
  height: 48px;
  border: 0;
  border-radius: 4px;
  font-size: 18px;
  margin: 16px 0 8px;
}

.btn-approve[disabled] {
  cursor: pointer;
  background: var(--components-button-primary-background-disabled);
}

@media screen and (max-width: 40em) {
  .btn-approve {
    font-size: 16px;
  }
}

.loader {
  border: 6px solid #f3f3f3;
  border-radius: 50%;
  border-top: 6px solid #4968ad;
  width: 30px;
  height: 30px;
  -webkit-animation: spin 2s linear infinite;
  /* Safari */
  animation: spin 2s linear infinite;
  margin: 10px auto;
}

#resend-timer-counter {
  display: inline-block;
  width: 22px;
  text-align: center;
}

/* Safari */

@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

a.disabled {
  opacity: 0.5;
  pointer-events: none;
  cursor: default;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.welcome {
  text-align: center;
}

.welcome .signed-in-logo {
  width: 100px;
  height: auto;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.welcome .store-btn {
  padding: 5px 10px;
}

.welcome h1 {
  font-size: xx-large;
}

.welcome h3 {
  font-size: x-large;
}

.btn-logout {
  color: var(--components-link-success-text-default);
  width: 100%;
  border: 0;
  border-radius: 4px;
  font-size: 18px;
  display: block;
  text-align: center;
  align-items: flex-start;
}

.password-hint {
  font-size: 14px;
  color: #6d6e70;
}

.pin-container {
  height: 48px;
  width: 216px;
  margin-bottom: 8px;
}
.pin-character {
  font-family: Inter, sans-serif;
  font-size: 16px;
  line-height: 24px;
  border: 1px solid var(--components-input-input-field-border-default);
  border-radius: 4px;
  height: 48px;
  width: 48px;
  padding: 12px 8px 12px 8px;
}
.pin-selected::after {
  display: none;
}
.pin-selected:empty::after {
  content: "";
  width: 1px;
  height: 25px;
  background: #000000;
  display: inline-block;
  animation: cursor-blink 1s steps(2) infinite;
}
@keyframes cursor-blink {
  0% {
    opacity: 0;
  }
}

.verify-card-hint {
  font-family: Inter, sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  text-align: left;
  margin-bottom: 4px;
}
.verify-email-hint,
.verify-name-hint {
  color: #6d6e70;
  font-family: Inter, sans-serif;
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  text-align: left;
  margin-bottom: 8px;
}
.btn-try-another-way {
  font-family: Inter, sans-serif;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  text-align: left;
  color: var(--components-link-success-text-default);
  background: none !important;
  border: none;
  padding: 0 !important;
  text-decoration: none;
  cursor: pointer;
}

.btn-try-another-way > img {
  margin-right: 2px;
  width: 20px;
  height: 20px;
}
.card-img-container {
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 256px;
  height: 256px;
  margin: 24px 0px 8px;
}
.card-img-container > img {
  width: 100%;
  height: 100%;
}
.largeIcon {
  font-size: 3em !important;
  text-align: center;
}

.warningColor {
  color: #ffce41;
}

.successColor {
  color: #37b44e;
}

.failureColor {
  color: var(--text-state-danger);
}

.signin-button {
  width: 250px;
  height: 40px;
  display: inline-block;
  vertical-align: bottom;
  margin: 5px;
  overflow: hidden;
}

.resend-btn {
  background: none !important;
  border: none;
  padding: 0 !important;
  color: var(--components-link-success-text-default);
  text-decoration: none;
  cursor: pointer;
}

.resend-btn:disabled {
  opacity: 0.5;
  text-decoration: none;
  pointer-events: none;
  cursor: default;
}

.resend-delimiter:before {
  content: "\00a0";
}

.resend-delimiter:after {
  content: "\00a0";
}
.social-button-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}
.signin-google-button {
  margin: 5px;
  height: 40px;
}

.signup-terms-text {
  font-size: 12px;
  font-weight: 200;
  line-height: 150%;
  color: var(--text-tertiary);
  margin-top: 2em;
  text-align: center;
}

#alternative-login {
  text-align: center;
}

.signed-in-logo {
  width: 100px;
  height: auto;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.careem-logo {
  width: 128px;
  position: relative;
  align-content: center;
  left: 160px;
}

.thumb-logo-container {
  padding-top: 80px;
  display: flex;
}

.mail-box-container {
  padding-top: 50px;
  display: flex;
}

.thumb-logo {
  margin: auto;
}

.careem-logo-container {
  display: flex;
  align-items: center;
  background: var(--background-brand-careem);
  height: 80px;
}

.email-verified-container {
  background: var(--background-primary);
  height: 100vh;
}

.email-message-container {
  text-align: center;
}

.open-careem-button-container {
  text-align: center;
  padding: 15px;
  display: none;
}

@media screen and (max-width: 40em) {
  .careem-logo {
    align-content: center;
    width: 128px;
    position: relative;
    left: 32px;
  }

  .email-verified-container {
    padding: 20px;
  }

  .open-careem-button-container {
    display: block;
  }
}
.recaptcha-v2-container{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

}